import { StaticInformationService } from './services/static-information.service';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import pkgInfo from '../../package.json';
import { LoggingService } from './services/logging.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { APIInfoService } from './services/content/api-info.service';
import { ApiInfo } from './models/apiinfo';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public version: string = pkgInfo.version;
  public title = 'Dashboard';
  private environmentMode = environment.mode;
  private apiInfo;

  constructor(
    private translate: TranslateService,
    private loggingSrv: LoggingService,
    private titleService: Title,
    private staticInformationSrv: StaticInformationService,
    private apiInfoService: APIInfoService,
  ) {
    this.titleService.setTitle(this.staticInformationSrv.getAppName());
    this.loggingSrv.log('VERSION:', this.version, 'info', true);
    this.loggingSrv.log('ENVIRONMENT:', this.environmentMode, 'info', true);
    this.getApiInfo();
    this.initTranslate();
  }

  initTranslate() {
    // Set the default language for translation strings, and the current language.
    // this.translate.addLangs(["nl", "en"]);
    this.translate.setDefaultLang('nl');
    const browserLang = this.translate.getBrowserLang();

    if (browserLang) {
      this.loggingSrv.log('BROWSER LANGUAGE:', browserLang, 'info', true);

      this.translate.use(this.translate.getBrowserLang());
    } else {
      this.translate.use('nl'); // Set your language here
    }
  }

  getApiInfo() {
    this.apiInfoService.getAPIGatewayInfo().subscribe(apiInfo => {
      this.apiInfo = apiInfo.endpoints[0] as ApiInfo;
      this.loggingSrv.log('BACK-END ENV:', this.apiInfo.environment, 'info', true);
      this.loggingSrv.log('BACK-END VERSION:', this.apiInfo.version, 'info', true);
      this.loggingSrv.log('BACK-END ADSOLUT:', this.apiInfo.adsolut, 'info', true);
    }, error => {
      this.loggingSrv.error(error);
    });
  }
}
