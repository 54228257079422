import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/users';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { StaticInformationService } from '../static-information.service';
import { LoggingService } from '../logging.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(
        private http: HttpClient,
        private router: Router,
        private loggingSrv: LoggingService,
        private staticInfo: StaticInformationService,
    ) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(`${this.staticInfo.getStoragePrefix()}currentUser`)));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    setCurrentUserValue(newUserValue: User) {
        this.currentUserSubject.next(newUserValue);
    }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.serverUrl}/${environment.api_version}/users/login`, { email: username, password: password })
            .pipe(map(result => {
                // login successful if there's a jwt token in the response
                this.loggingSrv.log(result.result);

                if (result?.result?.user && result?.result?.token) {
                    result.result.user.token = result.result.token;
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem(`${this.staticInfo.getStoragePrefix()}currentUser`, JSON.stringify(result.result.user));
                    this.currentUserSubject.next(result.result.user);
                }

                return result.result.user;
            }));
    }

    logout() {
        this.loggingSrv.log('Loging out...');
        // remove user from local storage to log user out
        localStorage.removeItem(`${this.staticInfo.getStoragePrefix()}currentUser`);
        this.currentUserSubject.next(null);
        this.router.navigate(['/login']);
    }
}
