import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor, JwtInterceptor } from './services/interceptors';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './services/auth/auth.guard';
import { SpinnerComponent } from './shared/spinner.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// Laad taalfunctionaliteit voordat de app gestart word
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER } from 'ngx-ui-loader';
// Global configuration for the spinner
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#f5f5f5',
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  blur: 5,
  fgsColor: '#f5f5f5',
  fgsType: SPINNER.foldingCube, // foreground spinner type
  fgsPosition: 'center-center',
  fgsSize: 60,
  gap: 30,
  hasProgressBar: true,
  logoPosition: 'top-center',
  logoSize: 100,
  logoUrl: 'assets/images/logo-spinner.png',
  overlayColor: 'rgba(19,91,170,.8)',
  pbColor: '#f5f5f5',
  pbThickness: 5, // progress bar thickness
  pbDirection: 'ltr',
  text: 'Laden ...',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ToastrModule.forRoot({
      closeButton: true
    }),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    MarkdownModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    AuthGuard
  ],
  bootstrap: [AppComponent],
  exports: [
    BrowserModule,
    TranslateModule,
  ]
})
export class AppModule { }
