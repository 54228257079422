export const environment = {
  production: false,
  mode: 'Acceptation',
  serverUrl: 'http://api-acc.bartisserver.be:9002',
  // serverUrl: 'http://api-acc.bartisserver.be:9002',
  // serverUrl: 'http://localhost:9002',
  api_version: 'api/v1',
  minLogLevel: 'info',
  googleAPIkey: 'AIzaSyB9DHfmf0ZrEfX52U6u2WMDZnXdRHKcr1A',
};
