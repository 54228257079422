import { Injectable } from '@angular/core';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import pkgInfo from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class StaticInformationService {

  constructor() { }

  private phoneNumberHQ = '+31613493685';
  private appVersion = pkgInfo.version;
  private appid = 'nl.scorpion.bartis-portaal';
  private appidforpush = 'nl.bartis.bestelapp';
  private appName = 'Bartis Horecaslagerij Dashboard';
  private roles: string[] = ['user', 'userplus', 'manager', 'admin'];
  private storagePrefix = 'bartis-dashboard-';
  private companyName = 'Bartis Horecaslagerij';
  private companyLogos = {
    imageBig: 'assets/images/Logo_Bartis_2024.png',
    imageSmall: 'assets/images/Logo_Bartis_2024.png',
    imageBigDark: 'assets/images/Logo_Bartis_2024.png',
    imageSmallDark: 'assets/images/Logo_Bartis_2024.png',
    icon: 'assets/images/Logo_Bartis_2024.png'
  };
  private testUsers = ['e@w', 'bart@bartis.be', 'ad@min', 't@t'];
  private weekDays = [
    {id: 1, value: 'Maandag', shortValue: 'MA'},
    {id: 2, value: 'Dinsdag', shortValue: 'DI'},
    {id: 3, value: 'Woensdag', shortValue: 'WO'},
    {id: 4, value: 'Donderdag', shortValue: 'DO'},
    {id: 5, value: 'Vrijdag', shortValue: 'VR'},
    {id: 6, value: 'Zaterdag', shortValue: 'ZA'},
    {id: 7, value: 'Zondag', shortValue: 'ZO'},
  ];

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '10rem',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '...',
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };

  getCompanyName() {
    return this.companyName;
  }

  getCompanyLogos() {
    return this.companyLogos;
  }

  getPhoneNumberHQ() {
    return this.phoneNumberHQ;
  }

  getAppVersion() {
    return this.appVersion;
  }

  getAppName() {
    return this.appName;
  }

  getAppID() {
    return this.appid;
  }

  getAppIDForPush() {
    return this.appidforpush;
  }

  getStoragePrefix() {
    return this.storagePrefix;
  }

  getRoles() {
    return this.roles;
  }

  getTestUsers() {
    return this.testUsers;
  }

  isTestUser(username) {
    return this.testUsers.some(tu => tu === username);
  }

  isMinimumRole(usersRole, minRole) {
    if (!usersRole || !minRole) { return false; }
    const mri = this.roles.findIndex(mrix => mrix === minRole);
    const uri = this.roles.findIndex(urix => urix === usersRole);
    return mri <= uri;
  }

  public getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getWeekdays() {
    return this.weekDays;
  }

  getDayOfTheWeek(id) {
    return this.weekDays.find(dy => dy.id === id);
  }
}
