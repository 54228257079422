import { HttpClient, HttpRequest, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class APIService {

  constructor(
    public http: HttpClient,
  ) { }


  /**
   * @desc Do Get Requests to the API-Gateway Server
   */
  doGetRequest(endPoint: string, headersValues?: Object, queryParams?) {
    let headers = new HttpHeaders();
    if (headersValues) {
      const keys = Object.keys(headersValues);
      keys.forEach(key => {
        headers = headers.set(key, String(headersValues[key]));
      });
    }

    const urlSearchParams = new URLSearchParams();
    if (queryParams) {
      Object.keys(queryParams).forEach(key => {
        urlSearchParams.append(key, queryParams[key]);
      });
    }

    return this.http.get(environment.serverUrl + '/' + environment.api_version + endPoint + '?' + urlSearchParams.toString(), { headers: headers }).pipe(map((result: any) => {
      return result;
    }));
  }

  /**
   * @desc Do a PUT to the Server
   */
  doPutRequest(endPoint: string, data: Object, headersValues?: Object, queryParams?) {
    let headers = new HttpHeaders();
    if (headersValues) {
      const keys = Object.keys(headersValues);
      keys.forEach(key => {
        headers = headers.set(key, String(headersValues[key]));
      });
    }

    const urlSearchParams = new URLSearchParams();
    if (queryParams) {
      Object.keys(queryParams).forEach(key => {
        urlSearchParams.append(key, queryParams[key]);
      });
    }

    return this.http.put(environment.serverUrl + '/' + environment.api_version + endPoint + (queryParams && Object.keys(queryParams).length > 0 ? '?' + urlSearchParams.toString() : ''), data).pipe(map((result: any) => {
      return result;
    }));
  }

  /**
   * @desc Do a POST to the Server
   */
  doPostRequest(endPoint: string, data: Object, headersValues?: Object) {
    let headers = new HttpHeaders();
    if (headersValues) {
      const keys = Object.keys(headersValues);
      keys.forEach(key => {
        headers = headers.set(key, String(headersValues[key]));
      });
    }
    return this.http.post(environment.serverUrl + '/' + environment.api_version + endPoint, data).pipe(map((result: any) => {
      return result;
    }));
  }

  /**
   * @desc Do Delete Requests to the API-Gateway Server
   */
  doDeleteRequest(endPoint: string, headersValues?: Object) {
    let headers = new HttpHeaders();
    if (headersValues) {
      const keys = Object.keys(headersValues);
      keys.forEach(key => {
        headers = headers.set(key, String(headersValues[key]));
      });
    }

    return this.http.delete(environment.serverUrl + '/' + environment.api_version + endPoint, { headers: headers }).pipe(map((result: any) => {
      return result;
    }));
  }

  // doFileUpload(fileKey, filePath, endPoint) {
  //   return new Promise((resolve,reject)=>{
  //     const fileTransfer: FileTransferObject = this.transfer.create();
  //     this._ss.getJSONKey('userdata').then(result => {
  //       let token = (result as any).token;
  //       let options: FileUploadOptions = {
  //         httpMethod:"PUT",
  //         fileKey,
  //         headers: {
  //         'Authorization': 'Bearer ' + token,
  //         }
  //       }
  //       fileTransfer.upload(filePath, this._config.config.serverUrl + '/' + this._config.config.api_version + endPoint, options)
  //        .then((data) => {
  //          resolve(data);
  //        }, (err) => {
  //          reject(err)
  //        })
  //     },error => reject(error));

  //   })
  // }

}
