import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard } from './services/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate : [AuthGuard],
  //   children: [
  //     { path: 'home', loadChildren: () => import('./pages/starter/starter.module').then(m => m.StarterModule), data: { title: 'Dashboard'} },
  //     // { path: 'home', redirectTo: '/starter'},
  //     // { path: 'page',  loadChildren: './pages/pages.module#PagesModule' },
  //   ]
  },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), data: { title: 'Login'} },
  { path: '**', redirectTo: 'login'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }), NgbModule],
  exports: [RouterModule]
})
export class AppRoutingModule {}
