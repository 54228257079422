import { Injectable } from '@angular/core';
import { APIService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class APIInfoService {

  constructor(
    private apiSrv: APIService,
  ) { }

  getAPIGatewayInfo(queryParams?) {
    return this.apiSrv.doGetRequest('/api-info', undefined, queryParams);
  }

}
