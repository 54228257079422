import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * @description This service is created to handle console messages
 * @author Edwin Witlox
 * @export
 * @class LoggingService
 */

 @Injectable({
  providedIn: 'root'
})


export class LoggingService {

  private loglevels: string[] = ['log', 'info', 'warn', 'error'];

  constructor() { }

  /**
   * @description The handling of all console messages is done throught this function
   * The minimum loglevel is set in the environment settings. This creates functionality not to display messages
   * that are not nescesary for that specific environment
   * @author Edwin Witlox
   * @param  {any} message The message that is send to the console
   * @param  {any} [objectToDisplay] If an object is added we also send this to the console
   * @param  {string} [logLevel] Logging level, the way the messages are displayed
   * @param  {boolean} [forceShow] Always send the messages to the console overrulling the minimum logLevel
   * @return {void}@memberof LoggingService
   */
  public log(message, objectToDisplay?, logLevel?: string, forceShow?: boolean) {
    if (!logLevel) { logLevel = 'log'; }
    if (this.isMinimumLogLevel(logLevel, environment.minLogLevel) || forceShow) {
      console[logLevel](message, objectToDisplay ? objectToDisplay : '');
    }
  }

  public info(message, objectToDisplay?, forceShow?: boolean) {
    this.log(message, objectToDisplay, 'info', forceShow);
  }

  public warn(message, objectToDisplay?, forceShow?: boolean) {
    this.log(message, objectToDisplay, 'warn', forceShow);
  }

  public error(message, objectToDisplay?, forceShow?: boolean) {
    this.log(message, objectToDisplay, 'error', forceShow);
  }

  /**
   * @description determine if a logging level (ll) is a minimum level (minll) or higher
   * @author Edwin Witlox
   * @param  {any} ll logging level
   * @param  {any} minll minimum logging level
   * @return boolean
   * @memberof LoggingService
   */
  isMinimumLogLevel(ll, minll) {
    if (!ll || !minll) { return false; }
    const mll = this.loglevels.findIndex(mllx => mllx === minll);
    const ull = this.loglevels.findIndex(ullx => ullx === ll);
    return mll <= ull;
  }

}
